import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import Restaurateur from "@/models/Restaurateur";
import Advertisement from "@/models/Advertisement.model";

/**
 * repository that deals with all the api requests that have to do with restauranteurs
 */
export default class AdvertisementRepository {
    private static baseUrl: string = 'api/advertisement';

    /**
     * gets the user based on the token that is used
     */
    public static getAdvertisements(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}`);
    }

    /**
     * gets the user based on the token that is used
     */
    public static getAllAdvertisements(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/all`);
    }

    /**
     * creates an advertisement
     */
    public static createAdvertisement(payload: {ad: Advertisement, file: File}): Promise<AxiosResponse> {
        const data = new FormData();
        data.append('file', payload.file);
        let queries = `?active=${payload.ad.active}&url=${payload.ad.url}&size=${payload.ad.size}`;
        if(payload.ad.activeFrom) queries += `&activeFrom=${payload.ad.activeFrom}`;
        if(payload.ad.activeTo) queries += `&activeTo=${payload.ad.activeTo}`;
        if(payload.ad.customerName) queries += `&customerName=${payload.ad.customerName}`;
        return ENTITY_CLIENT.post(`${this.baseUrl}${queries}`, data, {headers:
            {'Content-Type': 'multipart/form-data'}});
    }

    /**
     * updates the advertisement
     */
    public static updateAdvertisement(payload: {ad: Advertisement, file: File}): Promise<AxiosResponse> {
        const data = new FormData();
        if(payload.file) data.append('file', payload.file);
        let queries = `?active=${payload.ad.active}&url=${payload.ad.url}&size=${payload.ad.size}`;
        if(payload.ad.activeFrom) queries += `&activeFrom=${payload.ad.activeFrom}`;
        if(payload.ad.activeTo) queries += `&activeTo=${payload.ad.activeTo}`;
        if(payload.ad.customerName) queries += `&customerName=${payload.ad.customerName}`;
        return ENTITY_CLIENT.put(`${this.baseUrl}/${payload.ad.id}${queries}`, data, {
            headers: {'Content-Type': 'multipart/form-data'}});
    }

    /**
     * updates the advertisement
     */
    public static updateActiveState(ad: Advertisement): Promise<AxiosResponse> {
        return ENTITY_CLIENT.put(`${this.baseUrl}/${ad.id}?active=${ad.active}`);
    }

    /**
     * deletes an advertisement
     */
    public static deleteAdvertisement(id: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`${this.baseUrl}/${id}`);
    }
}
