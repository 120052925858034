
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AdvertisementRepository from "@/api/repositories/AdvertisementRepository";
import Advertisement from "@/models/Advertisement.model";

@Component({
  components: {
    AdvertisementListComponent: () => import(
      /* webpackChunkName: "AdvertisementListComponent" */
      '@/components/Advertisement/AdvertisementsList.component.vue'
    ),
    CreateAdvertisementComponent: () => import(
      /* webpackChunkName: "CreateAdvertisementComponent" */
      '@/components/Advertisement/CreateAdvertisement.component.vue'
    ),
    DeleteAdvertisementComponent: () => import(
      /* webpackChunkName: "DeleteAdvertisementComponent" */
      '@/components/Advertisement/DeleteAdvertisement.component.vue'
    ),
  }
})
export default class AdvertisementView extends Vue {
  private showActiveItems = true;

  private setListItems(active: boolean) {
    this.showActiveItems = active;
    const items = this.advertisements.filter((a: Advertisement) => a.active === active);
    this.advertisementList = [...items];
  }

  /**
   * list of all companies
   */
  private advertisements: Advertisement[] = [];

  /**
   * filtered list of advertisement that should be shown inside the list table
   * @private
   */
  private advertisementList: Advertisement[] = [];

  /**
   * flag that indicates if the list is loading
   * @private
   */
  private isLoading: boolean = false;

  /**
   * query string for the list of companies
   * @private
   */
  private search: string = '';

  /**
   * flag to show the edit dialog
   * @private
   */
  private showCreateAdvertisementDialog = false;

  /**
   * flag to show the delete dialog
   * @private
   */
  private showConfirmDeleteDialog = false;

  /**
   * clicked advertisement, track to check which advertisement to update or delete
   * @private
   */
  private clickedAdvertisement: Advertisement | null = null;

  /**
   * shows create advertisement dialog
   * @private
   */
  private createAdvertisement() {
    this.showCreateAdvertisementDialog = true;
  }

  /**
   * shows edit advertisement dialog
   * @param advertisement
   * @private
   */
  private onEditAdvertisement(advertisement: Advertisement) {
    this.clickedAdvertisement = advertisement;
    this.showCreateAdvertisementDialog = true;
  }

  /**
   * shows confirm delete dialog
   * @param advertisement
   * @private
   */
  private onDeleteAdvertisement(advertisement: Advertisement) {
    this.clickedAdvertisement = advertisement;
    this.showConfirmDeleteDialog = true;
  }

  private async onChangeAdvertisementActive(advertisement: Advertisement, active: boolean) {
    try {
      await AdvertisementRepository.updateActiveState(Advertisement.parseFromObject({id: advertisement.id, active}));
      this.setListItems(this.showActiveItems);
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ADVERTISEMENT_ACTIVE_CHANGE_ERROR');
    }
  }

  /**
   * closes all dialogs and reloads the table if needed
   * @param reload
   * @private
   */
  private async closeDialog(reload: boolean) {
    this.showCreateAdvertisementDialog = false;
    this.showConfirmDeleteDialog = false;
    this.clickedAdvertisement = null;
    if (reload) await this.fetchAdvertisements();
  }

  /**
   * gets all advertisement that should be shown inside the table
   * @private
   */
  private async fetchAdvertisements() {
    try {
      this.isLoading = true;
      const {data} = await AdvertisementRepository.getAllAdvertisements();
      this.advertisements = data.map((a: Advertisement) => Advertisement.parseFromObject(a));
      this.setListItems(true);
    } catch(_) {
      this.advertisements = [];
      this.setListItems(true);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * loads all companies on created
   */
  async created() {
    await this.fetchAdvertisements();
  }
}
