import Parseable from '@/misc/Parseable';
import {AdvertisementSize} from "@/enum/AdvertisementSize.enum";

export default class Advertisement extends Parseable {
    public id!: string;
    public active!: boolean;
    public size!: AdvertisementSize;
    public url!: string;
    public activeFrom?: string;
    public activeTo?: string;
    public file!: File;
    public customerName!: string;
    public resourceUrl!: string;

    public static parseFromObject(object: any): Advertisement {
        const cs = new Advertisement();
        Object.assign(cs, object);
        return cs;
    }

    public parseToObject(): Partial<Advertisement> {
        return { ...this };
    }
}
